<template>
    <section id="s" class="cart">
        <div v-if="cart.length">
            <div v-for="(item, index) in cart" :key="index" class="row mb-3">
                <div class="offset-md-2 col-md-9 col-sm-12">
                    <div class="row cart-item">
                        <div class="col">
                            <router-link :to="`/product/${item.id}`">
                                <img width="150" :src="item.images[0].image">
                                <!-- <img class="img-fluid mb-2" width="150" :src="`http://127.0.0.1:8000${item.images[0].image}`"> -->
                            </router-link>
                        </div>
                        <div class="col">
                            <div class="row">
                                <div style="text-transform: uppercase;" class="col">
                                    {{ item.title }}
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col">
                                    Velikost {{ item.selectedItemSize.size }}
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <b>{{ item.price * item.amount }},-</b>
                        </div>
                        <div class="col">
                            <div class="row mb-2">
                                <div class="col">
                                    <button class="btn btn-md btn-delete" @click="removeFromCart({productId: item.id, productSize: item.selectedItemSize.size})">Odstranit</button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-6 col-md-10 col-sm-10">
                                    <input min="1" :max="item.selectedItemSize.stock" :value="item.amount" @change="addAmount($event.target.value, `${item.id}`, `${item.selectedItemSize.size}`)" type="number" class="form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 row text-center">
                <div class="col">
                    <b>CELKEM {{ getTotalPrice() }},-</b>
                </div>
            </div>
            <div class="row mt-5 text-center">
                <div class="col">
                    <router-link to="/checkout">
                        <button class="btn btn-md btn-checkout">K pokladně</button>
                    </router-link>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row text-center">
                <div class="col">
                    <h3>V košíku nic není</h3>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "CartView",
    computed: {
        cart(){
            return this.$store.getters.getCart;
        }
    },
    methods: {
        removeFromCart(product){
            this.$store.dispatch('removeFromCart', product);
        },
        getTotalPrice(){
            let total = 0;
            for(let product of this.cart){
                total += parseInt(product.price) * product.amount;
            }

            return total;
        },
        addAmount(amount, itemId, size){
            let item = this.cart.find(item => item.id === parseInt(itemId) && item.selectedItemSize.size === size);
            
            item.amount = amount;
        }
    }
}
</script>

<style scoped>
    .cart{
        min-height: 100vh;
    }
    .cart-item{
        border-bottom: 1px solid black;
    }
    .btn-delete, .btn-delete:hover{
        background-color: black;
        color: white;
    }
    .btn-checkout{
        color: black;
        min-height: 50px !important;
        border: 1px solid black;
    }
    .btn-checkout:hover{
        color: white;
        background-color: black;
    }
</style>