<template>
    <section class="colors-section">
        <div id="s" class="row text-center">
            <div class="col mt-4 mb-3">
                <h3><i>VŠE NA CO SI VZPOMENEŠ MŮŽE DOSTAT SVOJI BARVU</i></h3>
            </div>
        </div>
        <div class="row text-center">
            <div class="col mb-5">
                <h3><i>MYSL JE OMEZENA POUZE NAŠÍ FANTAZIÍ</i></h3>
            </div>
        </div>

        <div class="row text-center">
            <div class="mt-3 mt-5 mb-5 offset-3 offset-md-4 offset-lg-1 col-lg col-sm-12">
                <div id="gallery">
                    <a
                     :href="images[0].largeURL"
                     :data-pswp-width="images[0].width"
                     :data-pswp-height="images[0].height"
                     target="_blank"
                     rel="noreferrer">                                
                        <div class="polaroid">
                            <div class="inside-overlay-1">
                                <div class="placeholder">
                                    <img src="/static/project_wheels.jpg">
                                </div>
                            </div>
                            <div class="inside-overlay-2">
                                <div class="placeholder">
                                    <img src="/static/project_wheels.jpg">
                                </div>
                                <div class="info">
                                    PROJECT WHEELS
                                </div>
                            </div>
                        </div>
                    </a>
                    <a
                     :href="images[1].largeURL"
                     :data-pswp-width="images[1].width"
                     :data-pswp-height="images[1].height"
                     target="_blank"
                     rel="noreferrer"></a>
                     <a
                     :href="images[2].largeURL"
                     :data-pswp-width="images[2].width"
                     :data-pswp-height="images[2].height"
                     target="_blank"
                     rel="noreferrer"></a>
                </div>
            </div>
            <div class="mt-5 mb-5 offset-3 offset-md-4 offset-lg-0 col-lg col-sm-12">
                <div class="polaroid">
                    <div class="inside-overlay-1">
                        <div class="placeholder">
                            <img src="/static/logo.png">
                        </div>
                    </div>
                    <div class="inside-overlay-2">
                        <div style="border: 1px solid lightgray;" class="placeholder">
                            <img style="margin-top: 30px;" src="/static/logo.png">
                        </div>
                        <div class="info">
                            COMING SOON
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5 offset-3 offset-md-4 offset-lg-0 col-lg col-sm-12">
                <div class="polaroid">
                    <div class="inside-overlay-1">
                        <div class="placeholder">
                            <img src="/static/logo.png">
                        </div>
                    </div>
                    <div class="inside-overlay-2">
                        <div style="border: 1px solid lightgray;" class="placeholder">
                            <img style="margin-top: 30px;" src="/static/logo.png">
                        </div>
                        <div class="info">
                            COMING SOON
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="contact" class="row">
            <div class="offset-1 mt-5 col-10">
                <div class="card mb-5">
                    <div class="card-title">
                        <div class="row mt-3 text-center">
                            <div class="col">
                                <h5 style="padding: 15px;">Chceš probudit barvou svojí všední věc, tak zanech kontakt! A začneme na tom společně pracovat.</h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form class="needs-validation" novalidate>
                            <div class="row mb-3">
                                <div class="col">
                                    <label for="exampleFormControlInput1" class="form-label">Jméno</label>
                                    <input v-model="contact.name" type="text" class="form-control" required>
                                    <div class="invalid-feedback">
                                        Toto pole je povinné
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col">
                                    <label for="exampleFormControlInput1" class="form-label">Místo</label>
                                    <input v-model="contact.place" type="text" class="form-control" required>
                                    <div class="invalid-feedback">
                                        Toto pole je povinné
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col">
                                    <label for="exampleFormControlInput1" class="form-label">Telefon</label>
                                    <input v-model="contact.phone" type="text" class="form-control" required>
                                    <div class="invalid-feedback">
                                        Toto pole je povinné
                                    </div>
                                </div>
                                <div class="col mb-3">
                                    <label for="exampleFormControlInput1" class="form-label">E-mail</label>
                                    <input v-model="contact.email" type="email" class="form-control" required>
                                    <div class="invalid-feedback">
                                        Toto pole je povinné
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col">
                                    <label for="exampleFormControlTextarea1" class="form-label">Co chceš pocákat</label>
                                    <textarea v-model="contact.idea" placeholder="oblečení, nábytek, auto…" class="form-control" id="exampleFormControlTextarea1" rows="4" required></textarea>
                                    <div class="invalid-feedback">
                                        Toto pole je povinné
                                    </div>
                                </div>
                            </div>
                            <div class="row text-center mb-3">
                                <div class="col">
                                    <button @click.prevent="sendContact"  class="button">Odeslat</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

export default {
    name: "ColorsView",
    data(){
        return {
            contact: {
                name: null,
                place: null,
                phone: null,
                email: null,
                idea: null
            },
            images: [
                {
                    largeURL:
                        '/static/project_wheels.jpg',
                    thumbnailURL:
                        '/static/project_wheels.jpg',
                    width: 3000,
                    height: 3000,
                },
                {
                    largeURL:
                        '/static/project_wheels2.jpg',
                    thumbnailURL:
                        '/static/project_wheels2.jpg',
                    width: 3000,
                    height: 3000,
                },
                {
                    largeURL:
                        '/static/project_wheels3.jpg',
                    thumbnailURL:
                        '/static/project_wheels3.jpg',
                    width: 2500,
                    height: 2500,
                }
            ],
        }
    },
    mounted() {
        if (!this.lightbox) {
            this.lightbox = new PhotoSwipeLightbox({
                gallery: '#gallery',
                children: 'a',
                pswpModule: () => import('photoswipe'),
            });
            this.lightbox.init();
        }
    },
    unmounted() {
        if (this.lightbox) {
            this.lightbox.destroy();
            this.lightbox = null;
        }
    },
    methods: {
        sendContact(){
            let form = document.getElementsByClassName('needs-validation')[0];

            if(form.checkValidity()){
                this.$store.dispatch('sendContact', this.contact);

                form.classList.add('was-validated');
                this.contact = {};
                form.classList.remove('was-validated');

                return;
            }

            form.classList.add('was-validated');
        }
    }
}
</script>

<style scoped>
    .colors-section{
        min-height: 100vh;
    }
    .mind{
        text-align: center;
        margin-top: 10%;
    }
    .start{
        text-align: center;
        margin-top: 10%;
        margin-bottom: 10%;
    }
    .polaroids{
        margin-top: 5%;
        text-align: center;
    }
    .polaroid {
        position: relative;
        border: 1px solid black !important;
        width: 220px !important;
        height: 320px !important;
        background-color: white;
        text-decoration: none;
        transition: transform 200ms;
        will-change: transform;
        color: black;
    }
    .info {
        font-family: 'Rock Salt';
    }
    @media (min-width: 550px) {
        .polaroid {
            width: 300px;
            height: 400px
        }
    }
    .inside-overlay-1,
    .inside-overlay-2 {
        position: absolute;
        top: -4px;
        left: -4px;
        width: 100%;
        height: 100%;
        background-color: white;
        border: 1px solid black;
        transition: transform 300ms;
        transform-origin: center;
        will-change: transform;
    }
    .polaroid,
    .inside-overlay--1,
    .inside-overlay--2 {
        box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
        0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
        0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
    }
    .placeholder {
        display: flex;
        margin: 1.5rem 1rem;
        opacity: 0.9 !important;
        cursor: pointer;
        filter: grayscale(100%);
    }
    img {
        width: 100%;
        object-fit: cover;
    }
    .inside-overlay-1 {
        transform: rotate(-5deg);
    }
    .inside-overlay-2 {
        transform: rotate(6deg);
    }
    .polaroid:hover > .inside-overlay-1,
    .polaroid:focus > .inside-overlay--1 {
        transform: rotate(-10deg);
    }
    .polaroid:hover > .inside-overlay-2,
    .polaroid:focus > .inside-overlay-2 {
        transform: rotate(10deg);
    }
    .polaroid:hover {
        cursor: pointer !important;
        transform: translateY(-8px);
    }
    .polaroid:hover .placeholder{
        filter: none;
    }
    .polaroid:focus {
        outline-offset: 12px;
    }
    .button{
        margin-top: 5%;
        background-color: white;
        color: black;
        padding: 10px;
        border-radius: 5px;
        min-width: 100px;
    }
    .button:hover{
        background-color: black;
        color: white;
        cursor: pointer;
    }


</style>