<template>
    <section>
        <div class="row text-center mt-3 mb-4">
            <div class="col">
                <h2>Správa objednávek</h2>
            </div>
        </div>
        
        <div v-for="order in orders" :key="order.id" class="row text-center">
            <div class="offset-3 col-6 mb-5">
                <router-link :to="`/admin/orders/${order.id}`">
                    <div class="card order">
                        <div class="card-body">
                            <h5 class="card-title">Objednávka číslo <b>#{{ order.id }}</b></h5>
                            <h6 class="card-subtitle mb-2 text-body-secondary">{{ order.ordered }}</h6>
                            <p>Jméno: {{ order.name }} Příjmení: {{ order.surname }}</p>
                            <p class="card-text">Status objednávky: {{ order.status }}  paid: {{ order.paid }}</p>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "OrderManagementView",
    computed: {
        orders(){
            return this.$store.getters.getOrders;
        }
    },
    async created(){
        await this.$store.dispatch('getOrders');
    }

}
</script>

<style scoped>
    .card:hover{
        cursor: pointer;
        border: 1px solid black;
    }
    a{
        text-decoration: none;
    }
</style>