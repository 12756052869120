import axios from 'axios'
import authHeader from './auth_header';

class OrderService{
    async createOrder(order){
        console.log(order);
        let response = await axios.post("/api/v1/orders/", order, { headers: authHeader() });

        return response.data;
    }
    async getOrders(){
        let response = await axios.get("/api/v1/orders/", { headers: authHeader() });

        return response;
    }
    async createStripeCheckout(line_items, shipping_rate_id, orderId){
        line_items.push(shipping_rate_id);
        line_items.push(orderId)

        let response = await axios.post("/api/v1/stripe/checkout/", line_items);

        return response.data;
    }
    async saveTransport(transport){
        await axios.post("/api/v1/transport/", transport);
    }
}

export default new OrderService();