import axios from 'axios'
import authHeader from './auth_header';

class ProductService{
    async getProducts(){
        let response = await axios.get("/api/v1/products/");

        return response;
    }
    async uploadImages(images){
        for(let image of images){
            await axios.postForm("/api/v1/images/", {
                image: image.image,
                product: image.product,
                index: image.index
            }, { headers: authHeader() });
        }
    }
    async addItem(product){
        let response = await axios.post("/api/v1/products/", product);

        return response;
    }
    async addSizes(sizes){
        await axios.post("/api/v1/sizes/", sizes, { headers: authHeader() });
    }
    sendContact(contact){
        axios.post("/api/v1/contacts/", contact);
    }
    async getNewContacts(){
        let response = await axios.get("/api/v1/contacts/", { headers: authHeader() });

        return response;
    }
    async addProperties(properties){
        await axios.post("/api/v1/properties/", properties, { headers: authHeader() });
    }
}

export default new ProductService();