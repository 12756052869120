import { createRouter, createWebHistory } from 'vue-router'

import store from "../store"

import HomeView from '../views/HomeView.vue'
import ProductView from '../views/ProductView.vue'
import CartView from '../views/CartView.vue'
import ColorsView from '../views/ColorsView.vue'
import CheckoutView from '../views/CheckoutView.vue'
import OrderSuccessView from '../views/OrderSuccessView.vue'
import AdminLoginView from '../views/admin_views/AdminLoginView.vue'
import AdminPanelView from '../views/admin_views/AdminPanelView.vue'
import ContactsView from '../views/admin_views/ContactsView.vue'
import OrderManagementView from '../views/admin_views/OrderManagementView.vue'
import AddItemView from '../views/admin_views/AddItemView.vue'
import OrderDetailView from '../views/admin_views/OrderDetailView.vue'
import HistoryView from '../views/HistoryView.vue'
import ComplaintView from '../views/ComplaintView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/product/:id',
    name: 'product',
    component: ProductView
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartView
  },
  {
    path: '/colors',
    name: 'colors',
    component: ColorsView
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutView
  },
  {
    path: '/order-success',
    name: 'order-success',
    component: OrderSuccessView
  },
  {
    path: '/history',
    name: 'history',
    component: HistoryView
  },
  {
    path: '/complaint',
    name: 'complaint',
    component: ComplaintView
  },
  {
    path: '/hulvat',
    name: 'admin-login',
    component: AdminLoginView
  },
  {
    path: '/admin-panel',
    name: 'admin-panel',
    component: AdminPanelView,
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/admin/contacts',
    name: 'contacts',
    component: ContactsView,
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/admin/orders',
    name: 'orders',
    component: OrderManagementView,
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/admin/add-item',
    name: 'add-item',
    component: AddItemView,
    meta: {
      requiredLogin: true
    }
  },
  {
    path: '/admin/orders/:id',
    name: 'add-order',
    component: OrderDetailView,
    meta: {
      requiredLogin: true
    }
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 75,
        behavior: 'smooth'
      }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiredLogin && !store.state.token)){
    next('/');
  }
  else{
    next();
  }
});

export default router
