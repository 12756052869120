import axios from 'axios'

class AuthService{
    async login(user){
        try{
            let response = await axios.post("/api/v1/token/", user);

            localStorage.setItem('user', JSON.stringify(response.data.access));

            return response;
        }
        catch(error){
            return error.response;
        }
    }
    logoutUser(){
        localStorage.removeItem('user');
    }
}

export default new AuthService();