<template>
    <section id="s" class="product-view">
        <div class="row">
            <div class="offset-sm-0 offset-lg-1 offset-md-0 col-lg-4 col-md-12 col-sm-12">
                <div class="row">
                    <div class="offset-lg-2 col-lg-8 col-md-8 col-sm-12">
                        <image-magnifier 
                         :src="selectedImage"
                         :zoom-src="selectedImage"
                         width="400"
                         height="400"
                         zoom-width="350"
                         zoom-height="350"
                        ></image-magnifier>
                    </div>
                </div>
                <div class="row">
                    <div class="product-image col-3 offset-sm-0 offset-md-1 col-lg-3 col-sm-2 col-md-3" v-for="(image, index) in product.images" :key="index">
                        <img class="img-fluid" @click="setImage(image.image)" width="125" :src="image.image">
                        <!-- <img class="img-fluid" @click="setImage(image.image)" width="100" :src="`http://127.0.0.1:8000${image.image}`"> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-sm-12 mt-5">
                <div class="row text-lg-left text-sm-center">
                    <div class="col">
                        <h1 class="text-uppercase"><i><b>{{ product.title }}</b></i></h1>
                    </div>
                </div>
                <div class="row text-lg-left text-sm-center">
                    <div class="col">
                        <h2><i><b>{{ product.subtitle }}</b></i></h2>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col">
                        <h3 style="padding: 0;">{{ product.price }},-</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="offset-2 col-8">
                        {{ product.description }} 
                    </div>
                </div>
                <div class="row mt-4" v-if="product.properties.length">
                    <div class="offset-2 col-8">
                        <ul style="padding: 0;margin-left:15px;">
                            <li v-for="property in product.properties" :key="property.id">
                                {{ property.product_property }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row mt-3 text-center">
                    <div class="col">
                        <b>Buď originál, buď hulvát!</b>
                    </div>
                </div>
                <div v-if="!isSoldOut" class="row mt-5">
                    <div class="offset-1 offset-lg-2 col-4">
                        <select style="padding: 10px;" v-model="selectedItemSize" class="form-select">
                            <option :value="null">Velikost</option>
                            <option v-for="size in product.sizes" :key="size.id" :value="size">
                                {{ size.size }}
                            </option>
                        </select>
                    </div>
                    <div class="offset-2 col-4">
                        <button
                         v-if="selectedItemSize != null && sizeNotInCart()"
                         @click="addToCart" 
                         class="btn btn-lg">
                        Do košíku</button>
                        <button v-else class="btn btn-lg" disabled>V košíku</button>
                    </div>
                </div>
                <div v-else class="row">
                    <div class="col-4">
                        Sorry kámo, ale jdeš pozdě..
                    </div>
                </div>
            </div>
        </div>
        <!-- <div v-if="!isLoading">
            <div class="container">
        <div v-else>
            Loading...
        </div> -->
    </section>
</template>

<script>
import ImageMagnifier from "../components/Magnifier.vue"

export default {
    name: "ProductView",
    components: {
        ImageMagnifier
    },
    data(){
        return {
            selectedImage: null,
            selectedItemSize: null
        }
    },
    computed: {
        isLoading(){
            return this.$store.getters.getIsLoading;
        },
        cart(){
            return this.$store.getters.getCart;
        },
        product(){
            let product = this.$store.getters.getProductById(parseInt(this.$route.params.id));
            let sizes = [];

            product.sizes.forEach(size => {
                if(size.stock > 0) sizes.push(size);
            });
            
            let orderedSizes = ["XS", "S", "M", "L", "XL", "XXL", "UNIVERZAL"]

            sizes.sort((a, b) => orderedSizes.indexOf(a.size) - orderedSizes.indexOf(b.size));

            product.sizes = sizes;

            return product;
        },
        isSoldOut(){
            return this.$store.getters.getProductById(parseInt(this.$route.params.id)).sizes.map(size => size.stock).every(amount => amount === 0);
        }
    },
    created(){
        this.getIndexImage();
    },
    methods: {
        sizeNotInCart(){
            let inCartProducts = this.cart.filter(product => product.id === this.product.id);
            let inCartSizes = [];

            for(let product of inCartProducts){
                inCartSizes.push(product.selectedItemSize.size);
            }

            return inCartSizes.includes(this.selectedItemSize.size) ? false : true;
        },
        getIndexImage(){
            if(!this.isLoading){
                let indexImageUrl = this.product.images.find(image => image.index === true).image;
                this.selectedImage = indexImageUrl;
                // this.selectedImage = `http://127.0.0.1:8000${indexImageUrl}`;
            }
        },
        setImage(image){
            this.selectedImage = image;
            // this.selectedImage = `http://127.0.0.1:8000${image}`;
        },
        addToCart(){
            this.$store.dispatch('addToCart', {...this.product, selectedItemSize: this.selectedItemSize});
        }
    }
}
</script>

<style scoped>
    .product-view{
        min-height: 100vh;
    }
    .buttons{
        margin-top: 60px;
    }
    .content{
        margin-top: 40px;
    }
    .btn{
        padding: 5px;
        color: black;
        border: 1px solid black;
        background-color: white;
    }
    .button-disabled{
        padding: 5px;
    }
    .btn:hover{
        background-color: black;
        color: white;
        cursor: pointer;
    }
    .product-image:hover{
        cursor: pointer;
    }
</style>