<template>
    <section class="checkout">
        <div class="row text-center mt-5">
			<div class="col">
				<div class="apple-pay">
					<img width="250" class="img-fluid" src="/static/apple_pay.png" />
				</div>
			</div>
		</div>

        <div class="row">
            <div class="offset-md-2 col-md-8 col-sm-12">
                <div style="margin-top: 6%;margin-bottom: 1%;width: 100%; height: 18px; border-bottom: 1px solid gray; text-align: center">
                    <span style="color: gray; font-size: 20px; background-color: #FFFFFF; padding: 0 50px;">
                        NEBO
                    </span>
                </div>
            </div>
        </div>

        <div class="row mt-5 mb-3">
			<div class="col">
				<nav aria-label="breadcrumb">
				  <ol v-if="step === 1" class="breadcrumb justify-content-center">
					<li class="breadcrumb-item active" aria-current="page">Fakturace</li>
					<li class="breadcrumb-item">Doprava</li>
					<li class="breadcrumb-item">Platba</li>
				  </ol>
                  <ol v-else-if="step === 2" class="breadcrumb justify-content-center">
					<li @click="() => {step = 1}" class="breadcrumb-item bread-link">Fakturace</li>
					<li class="breadcrumb-item active" aria-current="page">Doprava</li>
					<li class="breadcrumb-item">Platba</li>
				  </ol>
                  <ol v-else class="breadcrumb justify-content-center">
					<li @click="() => {step = 1}" class="breadcrumb-item bread-link">Fakturace</li>
					<li @click="() => {step = 2}" class="breadcrumb-item bread-link">Doprava</li>
					<li class="breadcrumb-item active" aria-current="page">Platba</li>
				  </ol>
				</nav>
			</div>
		</div>

        <div class="row text-left">
            <div class="col-8">
                <component :is="stepComponent" />
            </div>
            <div class="col-4">
				<div class="row">
					<div class="col">
						<div class="card">
							<div class="card-body">
								<div class="row text-center">
									<div class="col">
										<h5>Shrnutí objednávky</h5>
									</div>
								</div>
								<div class="row text-center mt-5">
									<div class="col">
										Mezisoučet:
									</div>
									<div class="col">
										{{ getPrices().subtotal }},-
									</div>
								</div>
								<div class="row mt-3 text-center">
									<div class="col">
										Poštovné: 
									</div>
									<div class="col">
										{{ transportPrice.price }},-
									</div>
								</div>
                                <div v-if="payByCash" class="row mt-3 text-center">
                                    <div class="col">
                                        Dobírka:
                                    </div>
                                    <div class="col">
                                        50,-
                                    </div>
                                </div>
								<hr>
								<div class="row text-center">
									<div class="col">
										<b>Celkem</b>
									</div>
									<div class="col">
										<b>{{ getPrices().total }},-</b>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-3 text-center">
					<div class="col">
						<div class="card">
							<div class="card-body">
								<div class="row mb-5">
									<div class="col">
										<h5>Položky ({{ cart.length }})</h5>
									</div>
								</div>
								<div v-for="product in cart" :key="product.id" class="row mb-3">
                                    <div class="col">
                                        <div class="row">
                                            <img width="100" :src="product.images[0].image">
                                            <!-- <img width="80" :src="`http://127.0.0.1:8000${product.images[0].image}`"> -->
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="row">
                                            <div class="col">
                                                {{ product.title }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                Velikost {{ product.selectedItemSize.size }}
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                Kusů: {{ product.amount }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div class="row">
                                            {{ product.price }},-
                                        </div>
                                    </div>
                                </div>
							</div>
						</div>	
					</div>
				</div>
			</div>
        </div>
		
		<div class="row mb-5 mt-5 text-center">
            <div v-if="step > 1" class="col">
				<button @click="previousStep" class="btn btn-lg">Zpět</button>
			</div>
			<div v-if="step <= 2" class="col">
			    <button @click="validateStep" class="btn btn-lg">Dále</button>
			</div>
            <div v-else class="col">
                <!-- <button v-if="payByCash" @click="confirmOrder" class="btn btn-lg">Dokončit</button> -->
                <button v-if="payByCash && !loading" @click="confirmOrder" class="btn btn-lg">Dokončit</button>
                <button v-else class="btn btn-lg" disabled><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Dokončit</button>
            </div>
		</div>
    </section>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
    name: "CheckoutView",
    data(){
        return {
            loading: false,
            step: 1,
        }
    },
    async created(){
        await this.$store.dispatch('fetchTransportOptions');
    },
    computed: {
        stepComponent(){
            this.step;
            return defineAsyncComponent(() => import(`@/components/checkout-wizard/Wizard-step${this.step}.vue`));
        },
        payByCash(){
            return this.$store.getters.getPayByCash;
        },
        cart(){
            return this.$store.getters.getCart;
        },
        transportPrice(){
            return this.$store.getters.getTransportPrice;
        }
    },
    methods: {
        validateStep(){
            if(this.step === 1){
                let form = document.getElementsByClassName('needs-validation')[0];

                form.checkValidity() ? this.step += 1 : this.step = 1;

                form.classList.add('was-validated');
            }
            else if(this.step === 2){
                let form = document.getElementsByClassName('needs-validation')[0];

                form.checkValidity() ? this.step += 1 : this.step = 2;

                form.classList.add('was-validated');
            }
            else this.step += 1;
        },
        previousStep(){
            this.step -= 1;
        },
        getPrices(){
            let subtotal = 0;
            let total = 0;
            let shipping = this.transportPrice.price;

            for(let product of this.cart){
                subtotal += parseInt(product.price) * product.amount;
            }

            this.payByCash ? total = subtotal + shipping + 50 : total = subtotal + shipping;

            return {total, subtotal};
        },
        async confirmOrder(){
            this.loading = true;
            await this.$store.dispatch('createOrder');
            this.loading = false;

            this.$router.push('/order-success');
        }
    }
}
</script>

<style scoped>
.checkout{
    min-height: 100vh;
}
button{
    border: 1px solid black !important;
}
button:hover{
    color: white;
    background-color: black;
}
.breadcrumb-item{
    font-size: 1.3rem;
}
.breadcrumb-item a{
    color: black;
}
.apple-pay:hover{
    cursor: pointer;
}
.bread-link{
    text-decoration: underline;
}
.bread-link:hover{
    cursor: pointer;
}
</style>