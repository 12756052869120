import { createStore } from 'vuex'

import ProductService from '../services/product.service';
import OrderService from '../services/order.service';
import AuthService from '../services/auth.service';
import TransportService from '../services/transport.service';

export default createStore({
  state: {
    token: JSON.parse(localStorage.getItem('user')) || null,
    isLoading: false,
    cart: [],
    products: [],
    contacts: [],
    orders: [],
    transportPrice: {id: 2, name: "Zásilkovna výdejní místo", price: 79, shipping_rate_id: "shr_1NGlfRJvwiIWY0w6oHnBPv6D"},
    transportOptions: [],
    payByCash: false,
    invoiceData: {},
    transportationData: {},
    packetaAddress: null,
    KTMAddress: null
  },
  getters: {
    getCart: state => state.cart,
    getOrders: state => state.orders,
    isAuthenticated: state => state.token ? true : false,
    getIsLoading: state => state.isLoading,
    getPayByCash: state => state.payByCash,
    getContacts: state => state.contacts,
    getProducts: state => state.products,
    getTransportPrice: state => state.transportPrice,
    getTransportOptions: (state) => (search) => {
      const p = Array.from(search).reduce((a, v, i) => `${a}[^${search.substr(i)}]*?${v}`, '');
      const re = RegExp(p);
  
      return state.transportOptions.filter(pickupPlace => pickupPlace.city.match(re) || pickupPlace.zip.match(re));
    },
    getPacketaTransportOptions: state => state.transportOptions.packeta,
    getKtmTransportOptions: state => state.transportOptions.ktm,
    getProductById: (state) => (id) => {return state.products.find(product => product.id === id)},
    getOrderById: (state) => (id) => {return state.orders.find(order => order.id === id)},
    getInvoiceData: state => state.invoiceData,
    getPacketaAdress: state => state.packetaAddress,
    getKTMAddress: state => state.KTMAddress
  },
  actions: {
    setLoading({commit}, loading){
      commit('SET_LOADING', loading);
    },
    async login({commit}, user){
      let response = await AuthService.login(user);

      if(response.status === 200){
        commit('AUTHENTICATE_USER', response.data.access);
      }

      return response;
    },
    async addItem({dispatch}, payload){
      let response = await ProductService.addItem(payload.product);

      let createdProductId = response.data.id;
      
      let sizes = [];
      for(let size of payload.sizes){
        sizes.push({...size, "product": createdProductId});
      }

      let images = [];
      images.push({"image": payload.images[1], "product": createdProductId, "index": true});

      for(let image of payload.images[0]){
        images.push({image, "product": createdProductId, "index": false});
      }

      for(let property of payload.properties){
        property.product = createdProductId;
      }

      Promise.all([
        await ProductService.addSizes(sizes),
        await ProductService.addProperties(payload.properties),
        await ProductService.uploadImages(images)
      ]);

      await dispatch('getProducts');
    },
    async getOrders({commit}){
      let response = await OrderService.getOrders();

      commit('SET_ORDERS', response.data);
    },
    async getProducts({commit}){
      let response = await ProductService.getProducts();

      commit('SET_PRODUCTS', response.data);
    },
    async saveTransport(_, order){
      let transport = {transport_id: this.state.transportPrice.id, order}
      
      if(this.state.packetaAddress) transport.ktm_address = this.state.packetaAddress;
      else if(this.state.KTMAddress) transport.ktm_address = this.state.KTMAddress;

      await OrderService.saveTransport(transport);
    },
    async createOrder({_, dispatch, state}){
      let items = state.cart.map(item => item.id);
      let sizes = state.cart.map(size => size.selectedItemSize.id);

      let order_data = await OrderService.createOrder({...this.state.invoiceData, items, sizes, delivery_cash: state.payByCash});

      await dispatch('saveTransport', order_data.id);
      
      dispatch('clearCart');
      dispatch('getProducts');

      return order_data;
    },
    async getContacts({commit}){
      let response = await ProductService.getNewContacts();

      commit('SET_CONTACTS', response.data);
    },
    async fetchTransportOptions({commit}){
      let response = await TransportService.fetchTransportOptions();

      commit('SET_TRANSPORT_OPTIONS', response.data);
    },
    async createStripeCheckout({_, state}, orderId){
      let line_items = [...state.cart.map((item) => {return {quantity: item.amount, price: item.stripe_price_id}})]
      let shipping_rate_id = state.transportPrice.shipping_rate_id;

      let response = await OrderService.createStripeCheckout(line_items, shipping_rate_id, orderId);

      return response;
    },
    logout({commit}){
      AuthService.logoutUser();

      commit('LOGOUT_USER');
    },
    setPaymentMethodAsCash({commit}, flag){
      commit('SET_PAY_BY_CASH', flag);
    },
    clearCart({commit}){
      commit('CLEAR_CART');
    },
    sendContact(_, contact){
      ProductService.sendContact(contact);
    },
    addToCart({commit}, item){
      commit('ADD_TO_CART', {...item, amount: 1});
    },
    removeFromCart({commit}, product){
      commit('REMOVE_FROM_CART', product);
    },
    setTransportPrice({commit}, transportOption){
      commit('SET_TRANSPORT_OPTION', transportOption);
    },
    saveInvoiceData({commit}, invoiceData){
      commit('SAVE_INVOICE_DATA', invoiceData);
    },
    saveTransportationData({commit}, transportationData){
      commit('SAVE_TRANSPORTATION_DATA', transportationData);
    },
    setPacketaAddress({commit}, address){
      commit('SET_PACKETA_ADDRESS', address);
    },
    setKTMAddress({commit}, address){
      commit('SET_KTM_ADDRESS', address);
    }
  },
  mutations: {
    AUTHENTICATE_USER(state, token){
      state.token = token;
    },
    LOGOUT_USER(state){
      state.token = null;
    },
    SET_PRODUCTS(state, products){
      state.products = products;
    },
    SET_ORDERS(state, orders){
      state.orders = orders;
    },
    ADD_TO_CART(state, item){
      state.cart.push(item);
    },
    REMOVE_FROM_CART(state, product){
      // console.log(state.cart[0])
      // let newCart = state.cart.filter(item => item.id === parseInt(product.id) && item.selectedItemSize.size !== product.productSize);

      // state.cart = newCart;
      state.cart.splice(state.cart.findIndex(delProduct => parseInt(delProduct.id) === parseInt(product.id) && delProduct.selectedItemSize.size === product.productSize), 1);
    },
    CLEAR_CART(state){
      state.cart = [];
    },
    SET_LOADING(state, loading){
      state.isLoading = loading;
    },
    SET_CONTACTS(state, contacts){
      state.contacts = contacts;
    },
    SET_TRANSPORT_OPTIONS(state, transportOptions){
      state.transportOptions = transportOptions;
    },
    SET_TRANSPORT_OPTION(state, transportOpion){
      state.transportPrice = transportOpion;
    },
    SET_PAY_BY_CASH(state, flag){
      state.payByCash = flag;
    },
    SAVE_INVOICE_DATA(state, invoiceData){
      state.invoiceData = invoiceData;
    },
    SAVE_TRANSPORTATION_DATA(state, transportationData){
      state.transportationData = transportationData;
    },
    SET_PACKETA_ADDRESS(state, address){
      state.packetaAddress = address;
    },
    SET_KTM_ADDRESS(state, address){
      state.KTMAddress = address;
    }
  }
})
