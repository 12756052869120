<template>
    <section>
        <div class="row text-center mt-3">
		<div class="col">
			<h2>Nové kontakty</h2>
		</div>
	</div>

	<div v-for="contact in contacts" :key="contact.id" class="row text-center mt-3 mb-5">
		<div class="offset-3 col-6">
			<div class="card">
			  <div class="card-body">
                <h5 class="card-title">Nový kontakt od <b>{{ contact.name }}</b></h5>
				<h6 class="card-subtitle mb-2 text-body-secondary">{{ contact.created }}</h6>
				<div class="row">
					<div class="col">
						{{ contact.phone }}
					</div>
				</div>
				<div class="row">
					<div class="col">
						{{ contact.place }}
					</div>
				</div>
				<div class="row">
					<div class="col">
						{{ contact.email }}
					</div>
				</div>
				<div class="row">
					<div class="col">
						{{ contact.idea }}
					</div>
				</div>

				<hr>
				
				<div class="row mt-2">
					<div class="col">
						<button type="button" class="btn btn-success">Označit jako viděné</button>
					</div>
					<div class="col">
						<button type="button" class="btn btn-outline-danger">Smazat</button>
					</div>
				</div>
			  </div>
			</div>
		</div>
	</div>
    </section>
</template>

<script>
export default {
    name: "ContactsView",
    computed: {
        contacts(){
            return this.$store.getters.getContacts;
        }
    },
    async created(){
        await this.$store.dispatch('getContacts');
    }
}
</script>