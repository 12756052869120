<template>
    <div class="modal fade" id="tac" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-scrollable modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">OBCHODNÍ PODMÍNKY</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                
                    <div class="row text-center mb-3">
                        <div class="col">
                        <img width="150" src="/static/logo.png" class="img-fluid">
                        </div>
                    </div>

                    <div class="row text-center">
                        <div class="col">
                            <p>Všeobecné obchodní podmínky internetového obchodu https://www.hulvattime.com</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <div class="row mb-2">
                                <div class="col">
                                    <b>1. Základní ustanovení</b>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    Tyto všeobecné obchodní podmínky (dále jen „​obchodní podmínky​”) obchodní společnosti hulvattime, se sídlem ​Radlická 48, 150 00, Praha, identifikační číslo:​ 88783243, DIČ: ​, (dále jen „​prodávající​“) jsou vydané dle § 1751 odst. 1. zákona č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů (dále jen „o​ bčanský zákoník​“).
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    1.2. Tyto obchodní podmínky upravují vzájemná práva a povinnosti smluvních stran vzniklé v souvislosti nebo na základě kupní smlouvy (dále jen „kupní smlouva“) uzavírané mezi prodávajícím a jinou fyzickou osobou (dále jen „kupující“) prostřednictvím internetového obchodu na adrese https://www.hulvattime.com​ ​(dále „​jen internetový obchod​”).
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    1.3. Obchodní podmínky a kupní smlouva jsou primárně vyhotoveny v českém jazyce, pokud si smluvní strany nedohodnou jazyk jiný. Smluvní vztah se řídí českým právním řádem v jeho účinném znění.
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col">
                                    1.4 Tyto obchodní podmínky vymezují vztah a práva a povinnosti z něj plynoucí pouze mezi prodávajícím a kupujícím – fyzickou osobou nepodnikatelem, který uzavírá smlouvu výlučně mimo rámec své podnikatelské činnosti či samostatného výkonu povolání.
                                </div>
                            </div>
                            <div class="row mb-4">
                                <div class="col">
                                    1.5 Znění obchodních podmínek může prodávající měnit či doplňovat na základě § 1752 zákona č. 89/2012 Sb., občanský zákoník. Změna obchodních podmínek bude kupujícímu oznámena prostřednictvím internetových stránek prodávajícího, konkrétně na https://www.hulvattime.com​ v Obchodních podmínkách​ s vyznačením data nabytí účinností nových podmínek a možností náhledu znění předchozích. Druhá strana má právo změny, v případě smlouvy uzavřené na dlouhodobé k opětovnému plnění, odmítnout a závazek z tohoto důvodu vypovědět ve lhůtě jednoho měsíce od doby nabytí účinnosti podmínek nových.
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <b>2. Objednávka zboží a uzavření kupní smlouvy</b>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            2.1​ ​Prezentace zboží, uvedeného v internetovém obchodě ​https://www.hulvattime.com​​,​ není nabídkou k uzavření smlouvy, je pouze informativního charakteru a prodávající není povinen uzavřít kupní smlouvu ohledně tohoto zboží.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            2.2 Ceny zboží jsou uvedeny​ včetně daně z přidané hodnoty, jeli prodávající plátcem takové daně (DPH) bez poštovného a balného. Cena uvedená u zboží je cenou za zboží konečnou. Cena zboží je platná po dobu, kdy je zobrazována v internetovém obchodě https://www.hulvattime.com.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            2.3 Internetový obchod ​https://www.hulvattime.com​ ​také obsahuje na specifickém místě informace o nákladech spojených s balením a dodáním zboží v rámci jeho doručování na území České republiky.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            2.4 Náklady spojené s balením a dodáním zboží na území České republiky, jsou vždy uvedeny před odesláním objednávky prostřednictvím stránek tohoto internetového obchodu. Náklady jsou kalkulovány dle veřejných i soukromých ceníků přepravních firem v závislosti na váze, způsobu platby a způsobu dodání zvoleného v objednávkovém formuláři.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            2.5 Před odesláním objednávky prodávajícímu, je kupujícímu umožněno objednávku měnit a upravovat údaje, které do objednávky vložil a kontrolovat správnost vložených údajů.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            2.6 Náklady kupujícího vzniklé při použití komunikačních prostředků na dálku v souvislosti s uzavřením kupní smlouvy (náklady na telefonní hovory, náklady na internetové připojení, apod.) na straně kupujícího, hradí kupující sám.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            2.7 Kupující provádí objednání zboží těmito způsoby:
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            2.7 Kupující provádí objednání zboží těmito způsoby:
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="offset-1 col">
                            a) kupující „vloží” objednávané zboží do nákupního košíku internetového obchodu​, ​stiskem tlačítka „Koupit​”, ​prostřednictvím svého uživatelského účtu, pokud provedl v předchozím kroku registraci v internetovém obchodě ​https://www.hulvattime.com ​
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            2.8 Objednávku odešle kupující prodávajícímu, kliknutím na tlačítko ​„​Objednávka zakazující k platbě​​”. Veškeré údaje uvedené v objednávce jsou prodávajícím považovány za správné. Kupující tak potvrzuje, že vyplnil všechny povinné údaje správně, dle svého vědomí a svědomí při vytváření objednávky a kliknutím na tlačítko „​Objednávka zakazující k platbě​” souhlasí s těmito obchodními podmínkami. K potvrzení a souhlasu slouží zatrhávající políčko.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            2.9 Veškeré objednávky odeslané kupujícím prodávajícímu jsou považovány prodávajícím za závazné.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            2.10 Neprodleně po odeslání objednávky prodávajícímu, vystaví prodávající potvrzení o doručení objednávky kupujícímu prostřednictvím elektronické pošty na kontakt uvedený kupujícím v objednávce, nebo v uživatelském účtu internetového obchodu ​https://www.hulvattime.com​, ​kde provedl kupující registraci. Toto potvrzení není považováno za uzavření kupní smlouvy.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            2.11 Prodávajícímu vzniká při doručení objednávky od kupujícího právo na kontakt kupujícího za účelem doplnění chybějících údajů, případnou změnu a úpravu údajů v objednávce.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            2.12 Pokud to charakter objednávky vyžaduje, je prodávající oprávněn kontaktovat kupujícího a požadovat jeho součinnost k uzavření kupní smlouvy.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            2.13 Uzavření kupní smlouvy mezi prodávajícím a kupujícím vzniká až potvrzením objednávky prodávajícím. Potvrzení objednávky vystaví prodávající kupujícímu a zašle jej na e-mailovou adresu sdělenou mu kupujícím v objednávce.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            2.14 V případě technické poruchy na straně prodávajícího, zjevně nesprávného zobrazení cen produktů či kombinací slev způsobující minimální cenu objednávky internetového obchodu, není prodávající povinen zboží či službu za uvedenou cenu kupujícímu dodat. A to ani v případě potvrzení objednávky nebo její zaplacení bezhotovostní platbou (platební kartou, bankovním převodem, nebo jinou platební metodou).
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            2.15 Tyto obchodní podmínky se vztahují pouze na nákup zboží přes webový internetový obchod https://www.hulvattime.com
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <b>3. Cena</b>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            3.1 Cenu za zboží a případné náklady spojené s dopravou, úpravou produktu (pokud ji prodávající poskytuje přímo v internetovém obchodě), může kupující uhradit těmito způsoby:
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <ul>
                                <li>v hotovosti nebo platební kartou na dobírku, v místě vyzvednutí zásilky</li>
                                <li>bezhotovostně prostřednictvím platebního systému: ​ <b>Apple pay</b></li>
                                <li>bezhotovostně převodem na bankovní účet prodávajícího č. 1675251013/3030​, vedený u společnosti Air bank.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            3.2 Kupující je povinen prodávajícímu uhradit kupní cenu zboží, ale také náklady spojené s balením a dodáním zboží. Není-li uvedeno výslovně jinak, rozumí se dále kupní cenou i náklady spojené s dodáním zboží.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            3.3 V případě platby na dobírku nebo v hotovosti, je cena zboží splatná při převzetí nebo ke dni dodání zboží. V případě bezhotovostní platby je kupní cena splatná do dnů od uzavření kupní smlouvy.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            3.4 V případě bezhotovostní platby je kupující povinen uhradit kupní cenu společně s uvedeným variabilním symbolem, na bankovní účet prodávajícího. Závazek kupujícího je splněn připsáním náležité částky kupní ceny, na účet prodávajícího.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            3.5 Dle ustanovení §1820 odst. 1 písm. q) občanského zákoníku, prodávající nepožaduje po kupujícím zálohu, nebo jinou obdobnou platbu, pokud to nevyžaduje povaha produktu a nedomluví-li se prodávající s kupujícím jinak. Úhradu kupní ceny zboží před jeho odesláním, nelze považovat jako zálohu.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            3.6 Případná cenová zvýhodnění a slevy z kupní ceny zboží nelze vzájemně kombinovat, nerozhodne-li se prodávající jinak. O možné kombinace slev, zvýhodnění apod. a o konečné ceně zboží je kupující informován vždy před odesláním objednávky.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            3.7. Daňový doklad je prodávajícím vyhotoven až po uhrazení kupní ceny zboží kupujícím a je prodávajícím doručen kupujícímu na e-mailovou adresu sdělenou mu kupujícím v objednávce a/nebo fyzicky předán při osobním vyzvednutí zboží v provozovně prodávajícího a/nebo zaslán kupujícímu současně se zbožím.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            3.8. Prodávající může využívat systému přizpůsobení ceny osobě kupujícího na základě automatizovaného rozhodování dle předem definovaného algoritmu. Pokud je cena přizpůsobena kupujícímu, bude prodávající o této skutečnosti neprodleně kupujícího informovat.
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            3.9. V případě že je předmětem závazku mezi prodávajícím a kupujícím poskytnutí digitálního obsahu, služby digitálního obsahu a věci s digitálními vlastnostmi, který není dodán na hmotném nosiči, spotřebitel výslovně souhlasí se započetím plnění před uplynutím lhůty pro odstoupení od smlouvy a že bere na vědomí, že udělením souhlasu zaniká jeho právo odstoupit od smlouvy podle § 1837 písm. l).
                        </div>
                    </div>


                    <div class="row mb-2">
                        <div class="col">
                            <b>4. Dodání zboží</b>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            4.1 Náklady na dodání zboží jsou dle uvedeného způsobu a typu doručení vždy uvedeny v objednávce a následném potvrzení vydaném prodávajícím.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            4.2 Kupující je povinen převzít zboží na místě uvedeném v objednávce, neučiní-li tak, vystavuje se nebezpečí nedoručení zboží a jeho vrácení zpět prodávajícímu. V případě následně vyžádaného opakovaného doručení prodávajícím bere kupující na vědomí opakované náklady na dopravu ve výši její původní ceny.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            4.3 Kupující je povinen při přebírání zboží od přepravce důkladně zkontrolovat zásilku zda-li není poškozen obal nebo přímo samotné zboží. Pokud kupující nesouhlasí s převzetím zboží, je povinen tuto skutečnost bez odkladu oznámit přepravci. V případě zjištění závad na obalu nebo přímo na zboží, není kupující povinen zásilku od přepravce převzít.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            4.4 Zaplacením zboží a převzetím zboží od dopravce, nabývá kupující vlastnické právo a veškeré povinnosti s tím spojené. Převzetím zboží kupujícím přechází na kupujícího nebezpečí vzniku škody na zboží.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            4.5 Zboží je kupujícímu dodáno:
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <ul>
                                <li>Prostřednictvím společností:​ PPL, Zásilkovna​</li>
                                <li>Na adresu uvedenou kupujícím v objednávce</li>
                                <li>Ve výdejně zásilek společnosti:​ PPL, zásilkovna, výdej je​ zpoplatněn částkou 0 Kč.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            4.6 Zboží je přepravci předáno obvykle do ​od objednávání 48 hodin ​pracovních dnů.
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            4.7 Prodávající prohlašuje že jím prodávaný digitální obsah, služby digitálního obsahu a věci s digitálními vlastnostmi, zajišťují funkčnost s technickým a programovým vybavením, které se obvykle s digitálním obsahem, službou digitálního obsahu a věcí s digitálními vlastnostmi téhož druhu používá, aniž je zapotřebí je převést (kompatibilita), nebo s jiným technickým a programovým vybavením, než které se obvykle s digitálním obsahem, službou digitálního obsahu a věcí s digitálními vlastnostmi téhož druhu používá (interoperabilita), které jsou prodávajícímu známy nebo u nichž lze rozumně očekávat, že by mu mohly být známy.
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <b>5. Odstoupení od kupní smlouvy</b>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.1 Podle ustanovení § 1837 občanského zákoníku, nemůže kupující odstoupit od kupní smlouvy v těchto případech:
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <ul>
                                <li>o poskytování služeb, jestliže byly v plném rozsahu poskytnuty; v případě plnění za úplatu, pouze pokud započalo s předchozím výslovným souhlasem spotřebitele před uplynutím lhůty pro odstoupení od smlouvy a podnikatel před uzavřením smlouvy poučil spotřebitele, že poskytnutím plnění zaniká právo odstoupit od smlouvy,</li>
                                <li>o dodávce zboží nebo služby, jejichž cena závisí na výchylkách finančního trhu nezávisle na vůli podnikatele a k němuž může dojít během lhůty pro odstoupení od smlouvy,</li>
                                <li>o dodání alkoholických nápojů, jejichž cena byla ujednána v době uzavření smlouvy s tím, že dodání je možné uskutečnit až po uplynutí třiceti dnů a jejichž skutečná hodnota závisí na výchylkách trhu nezávislých na vůli podnikatele,</li>
                                <li>o dodávce zboží vyrobeného podle požadavků spotřebitele nebo přizpůsobeného jeho osobním potřebám,</li>
                                <li>o dodávce zboží, které podléhá rychlé zkáze, nebo zboží s krátkou dobou spotřeby, jakož i zboží, které bylo po dodání vzhledem ke své povaze nenávratně smíseno s jiným zbožím,</li>
                                <li>o neodkladné opravě nebo údržbě, která má být provedena v místě určeném spotřebitelem na jeho výslovnou žádost; to však neplatí pro provedení jiných než vyžádaných oprav či dodání jiného zboží než náhradních dílů nutných k provedení opravy nebo údržby,</li>
                                <li>o dodávce zboží v zapečetěném obalu, které z důvodu ochrany zdraví nebo z hygienických důvodů není vhodné vrátit poté, co jej spotřebitel porušil,</li>
                                <li>o dodávce zvukové nebo obrazové nahrávky nebo počítačového programu v zapečetěném obalu, pokud jej spotřebitel porušil,</li>
                                <li>o dodávce novin, periodik nebo časopisů s výjimkou smluv o předplatném na jejich dodávání,</li>
                                <li>o ubytování, přepravě zboží, nájmu dopravního prostředku, stravování nebo využití volného času, pokud má být podle smlouvy plněno k určitému datu nebo v určitém období,</li>
                                <li>uzavírané na základě veřejné dražby podle jiného zákona, které může být spotřebitel fyzicky přítomen, nebo</li>
                                <li>o dodání digitálního obsahu, který není dodán na hmotném nosiči, poté, co bylo započato s plněním; v případě plnění za úplatu, pokud započalo s předchozím výslovným souhlasem spotřebitele před uplynutím lhůty pro odstoupení od smlouvy, spotřebitel byl poučen, že tím právo odstoupit od smlouvy zaniká, a podnikatel mu poskytl potvrzení podle § 1824a odst. 1 a 2 nebo § 1828 odst. 3 a 4. 2 zákona č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.2 Pokud neplatí případy uvedené v odstavci 6.1, těchto všeobecných podmínek pro odstoupení od kupní smlouvy, je kupující oprávněn podle § 1829 odst. 1 občanského zákoníku, odstoupit od kupní smlouvy do 14 dnů od převzetí zboží.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.3 Pokud je objednávka rozdělena, popřípadě obsahuje několik částí (nebo zboží), které jsou dodávány v časovém rozestupu, lhůta pro odstoupení od kupní smlouvy začíná běžet od dodání poslední části (zboží).
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.4 Odstoupení od kupní smlouvy, musí být prodávajícímu odesláno v této lhůtě (nejpozději 14 dní od převzetí poslední části, nebo zboží objednávky).
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.5 V případě odstoupení od kupní smlouvy dle § 1829 odst. 1 občanského zákoníku, vrátí prodávající přijaté peněžní prostředky kupujícímu do ​14 dnů ​od přijetí zboží. Prodávající není povinen vrátit přijaté peněžní prostředky kupujícímu dříve, než bude zboží doručeno zpět prodávajícímu, nebo jinak dle dohody s kupujícím.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.6 Kupující vrátí prodávajícímu zboží, které nesmí být poškozené, nesmí jevit známky nadměrného užívání a nesmí být znečištěno či jinak znehodnoceno. Pokud je to možné, vrátí kupující zboží také v původním obalu. Kupující odpovídá podnikateli pouze za snížení hodnoty zboží, které vzniklo v důsledku nakládání s tímto zbožím jinak, než je nutné k tomu, aby se seznámil s povahou, vlastnostmi a funkčností zboží.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.7 Je-li v nabídce prodávajícího několik možností doručení zboží, je při odstoupení od smlouvy a vrácení zboží kupujícím zpět prodávajícímu vždy kupujícímu vrácena částka poštovného dle nejlevnějšího způsobu doručení dle dané varianty. Tato částka je pak vrácena prodávajícím kupujícímu, oproti nároku kupujícího na vrácení kupní ceny.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.8 Peněžní prostředky budou vráceny kupujícím stejným způsobem, jakým je prodávající přijal, pokud se s kupujícím nedohodne jinak.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.9 Je-li součástí objednávky dárek od prodávajícího, odstoupením od kupní smlouvy není kupující povinen tento dárek prodávajícímu vrátit.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.10 Odstoupení od kupní smlouvy je nutné zaslat prodávajícímu na ​doručovací​ adresu uvedenou v těchto obchodních podmínkách a nebo na e-mailovou adresu: hulvattime@gmail.com​, popřípadě jiným komunikačním kanálem využívaným prodávajícím. ​Prodávající bezodkladně potvrdí přijetí odstoupení od kupní smlouvy kupujícímu.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.11 Pro odstoupení od kupní smlouvy, je možné využít formuláře poskytnutého prodávajícím a uvedeného na konci těchto všeobecných obchodních podmínek. Formulář pro odstoupení od smlouvy je kupujícímu zasílám současně s potvrzením objednávky a je též k dispozici ke stažení samostatně na adrese​ https://www.hulvattime.com.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.12 Prodávající je oprávněn odstoupit od kupní smlouvy až do okamžiku převzetí zboží kupujícím. Může tak učinit v několika případech, jako je například vyprodání zásob, přerušení dodávky zboží od výrobce nebo dodavatele, či z důvodu nedostupnosti zboží a jiných případů vzniklých třetí stranou.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.13 V případě odstoupení od kupní smlouvy prodávajícím, prodávající bezodkladně informuje kupujícího prostřednictvím e- mailové adresy, telefonu nebo jiného komunikačního kanálu. Veškeré přijaté peněžní prostředky včetně nákladů za dopravu od kupujícího, budou vráceny prodávajícím zpět a to stejným způsobem, popřípadě jiným způsobem určeným kupujícím.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.14 Je-li předmětem závazku mezi prodávajícím a kupujícím poskytnutí digitálního obsahu, služby digitálního obsahu a věci s digitálními vlastnostmi, který není dodán na hmotném nosiči, končí lhůta pro odstoupení od kupní smlouvy uplynutím čtrnácti dnů ode dne uzavření smlouvy.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.15 Odstoupí-li kupující od smlouvy a byl-li mu v souvislosti s poskytováním digitálního obsahu odevzdán hmotný nosič, vydá jej prodávajícímu na jeho žádost a náklady bez zbytečného odkladu. Prodávající může o vydání hmotného nosiče požádat do čtrnácti dnů od ukončení závazku. A zároveň se kupující zdrží užívání dle § 2389o, odst. 2 zákona č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            5.16 Kupující nenese žádné náklady na odstoupení od kupní smlouvy, jestliže bylo předmětem kupní smlouvy dodání digitálního obsahu, služby digitálního obsahu a věci s digitálními vlastnostmi, který nebyl dodán na hmotném nosiči a prodávající jej dodal před uplynutím lhůty pro odstoupení od smlouvy, ačkoliv kupující o to výslovně nežádal, nebo nevzal výslovně na vědomí, že mu právo na odstoupení od smlouvy zanikne, nebo prodávající neposkytl kupujícímu potvrzení podle § 1824a odst. 1 a 2 nebo § 1828 odst. 3 a 4. zákona č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů.
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            5.17 Kupující nemusí uvádět konkrétní důvod odstoupení od kupní smlouvy.
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <b>6 Práva z vadného plnění</b>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            6.1 Práva z vadného plnění se řídí podle ustanovení § 1914 až 1925, § 2099 až § 2117, dále pak § 2161 až § 2174b zákona č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů, dále dle zákona č. č. 634/1992 Sb., o ochraně spotřebitele, ve znění pozdějších předpisů.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            6.2 Prodávající odpovídá kupujícímu, že věc při převzetí nemá vady. Zejména prodávající odpovídá kupujícímu, že v době, kdy kupující věc převzal,
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <ul>
                                <li>má věc vlastnosti, které si strany ujednaly, a chybí-li ujednání, takové vlastnosti, které prodávající nebo výrobce popsal nebo které kupující očekával s ohledem na povahu zboží a na základě reklamy jimi prováděné,</li>
                                <li>se věc hodí k účelu, který pro její použití prodávající uvádí nebo ke kterému se věc tohoto druhu obvykle používá,</li>
                                <li>věc odpovídá jakostí nebo provedením smluvenému vzorku nebo předloze, byla-li jakost nebo provedení určeno podle smluveného vzorku nebo předlohy,</li>
                                <li>je věc v odpovídajícím množství, míře nebo hmotnosti,</li>
                                <li>zboží vyhovuje požadavkům právních předpisů.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            6.3 Projeví-li se vada v průběhu dvanácti měsíců od převzetí, má se za to, že věc byla vadná již při převzetí. 7.4 Připouští-li to povaha zboží nebo služby, má kupující právo na kontrolu nebo předvedení funkci výrobku.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            6.5 Neoznámil-li kupující vadu bez zbytečného odkladu poté, co ji mohl při včasné prohlídce a dostatečné péči zjistit, soud mu právo z vadného plnění nepřizná. Jedná-li se o skrytou vadu, platí totéž, nebyla-li vada oznámena bez zbytečného odkladu poté, co ji kupující mohl při dostatečné péči zjistit, nejpozději však do dvou let po odevzdání věci.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            6.6 Vyskytne-li se na zboží vada, pro kterou nelze zboží využívat obvyklým způsobem, může kupující uplatnit u prodávajícího právo z vadného plnění („reklamace“) a požadovat:
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <ul>
                                <li>odstranění vady dodáním nové věci bez vady nebo dodáním chybějící vady • přiměřenou slevu z kupní ceny,</li>
                                <li>odstranění vady opravou věci</li>
                                <li>odstoupení od smlouvy.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            6.7 Kupující je oprávněn odstoupit od kupní smlouvy, pokud zboží:
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <ul>
                                <li>vykazuje podstatnou vadu, která znemožňuje jeho užívání obvyklým způsobem,</li>
                                <li>pokud nelze zboží používat kvůli opakujícím se vadám i vadám/vadě po provedené opravě,</li>
                                <li>při větším počtu vad (3 a více vad),</li>
                                <li>je z prohlášení prodávajícího nebo z okolností zjevné, že vada nebude odstraněna v přiměřené době nebo bez značných obtíží pro kupujícího.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            6.8 Kupující písemně informuje prodávajícího, jaké právo si zvolil při oznámení vady, nebo bez zbytečného odkladu po oznámení vady prodávajícímu. Provedenou volbu nemůže kupující změnit bez souhlasu prodávajícího; to neplatí, žádal-li kupující opravu vady, která se ukáže jako neopravitelná.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            6.9 Dokud kupující neuplatní právo na slevu z kupní ceny nebo neodstoupí od smlouvy, může prodávající dodat to, co chybí, nebo odstranit právní vadu. Jiné vady může prodávající odstranit podle své volby opravou věci nebo dodáním nové věci; volba nesmí kupujícímu způsobit nepřiměřené náklady.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            6.10 Právo z vadného plnění kupující nemůže uplatnit, pokud při převzetí o vadě věděl, nebo ji sám způsobil.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            6.11 Prodávající je povinen přijmout reklamaci na adrese provozovny nebo v sídle i místě podnikání, pokud to povaha a druh zboží umožňuje.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            6.12 Prodávající písemně vyrozumí kupujícího o výsledku reklamace.
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            6.13 Kupující je oprávněn uplatnit právo z vady, která se vyskytne u zboží v době dvaceti čtyř (24) měsíců od převzetí zboží. Jedná-li se však o zboží s uvedeným datem spotřeby, v takovém případě se lhůta zkracuje na datum spotřeby uvedené na obalu.
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <b>7. Mimosoudní řešení sporu</b>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            7.1 Vzájemné spory mezi prodávajícím a kupujícím řeší obecné soudy.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            7.2 Kupující má podle zákona č. 634/1992 Sb., o ochraně spotřebitele, v platném znění, právo na mimosoudní řešení spotřebitelského sporu z kupní smlouvy. Subjektem, který je oprávněn mimosoudní řešení sporu provádět je Česká obchodní inspekce (zkráceně ČOI) na adrese Štěpánská 567/15, 120 00, Praha 2 - Nové Město. Jak postupovat v případě mimosoudního řešení spotřebitelských sporů, včetně možnosti návrhu, naleznete na internetové stránce <a href="https://www.coi.cz​" target="_blank">https://www.coi.cz/​</a> a <a href="https://www.coi.cz/informace-o-adr/" target="_blank">https://www.coi.cz/informace-o-adr/</a>.
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            7.3 Pomoc v případě přeshraničního mimosoudního řešení spotřebitelských sporů zajišťuje Evropské spotřebitelské centrum Česká republika (zkráceně ESC ČR). Kontaktní adresa ESC ČR je Štěpánská 567/15, 120 00 Praha 2 - Nové Město. Rady a informace o jednotlivých trzích, včetně platformy pro řešení mimosoudních spotřebitelských sporů, naleznete na internetových stránkách <a href="https://evropskyspotrebitel.cz/" target="_blank">https://evropskyspotrebitel.cz/</a> a <a href="​https://www.ec.europa.eu/consumers/odr/main/" target="_blank">​https://ec.europa.eu/consumers/odr/main/</a>.
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <b>8. Závěrečná ustanovení</b>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            8.1 Ujednání plynoucí z těchto obchodních podmínek a dle nich uzavřené kupní smlouvy mezi kupujícím a prodávajícím, v případě vstupu zahraničního subjektu, jsou podřízeny zákonům České republiky. Tímto však nejsou dotčena práva kupujícího dle nařízení Evropského parlamentu a Rady (ES) č. 593/2008 o právu rozhodném pro smluvní závazkové vztahy (Řím I).
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            8.2 V případě zásahu vyšší moci nebo jakémkoliv jiném výpadku internetového obchodu, není prodávající odpovědný za nemožnost realizace objednávky.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            8.3 V kupní smlouvě je možné sjednat odchylná ujednání, která pak mají přednost před ustanovením těchto obchodních podmínek.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            8.4 Prodávající si vyhrazuje právo na změnu či doplnění těchto obchodních podmínek. Tímto ustanovením však není dotčeno právo a povinnosti, dle předchozí verze všeobecných podmínek.
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <b>Tyto obchodní podmínky nabývají účinnosti 01.02.2023.</b>
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <b>9. Reklamační řád</b>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.1 Tento reklamační řád je nedílnou součástí všeobecných obchodních podmínek internetového obchodu ​ https://www.hulvattime.com​, a definuje základní podmínky a způsob reklamace vad zboží kupujícím.Tento reklamační řád vychází z ustanovení zákona č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů a zákona č. 634/1992 Sb., o ochraně spotřebitele. Tento reklamační řád vymezuje vztah mezi prodávajícím a kupujícím - fyzickou osobou nepodnikatelem, který uzavírá smlouvu výlučně mimo rámec své podnikatelské činnosti či samostatného výkonu povolání.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.2 Kupující je povinen se seznámit s všeobecnými obchodními podmínkami a reklamačním řádem, ještě před objednáním zboží, nejpozději se s nimi však kupující seznámí před odesláním objednávky v internetovém obchodě https://www.hulvattime.com​, když souhlas s jejich porozuměním a seznámením se s nimi potvrdí kupující zaškrtnutím příslušného pole při vytváření objednávky samotné. Bez zaškrtnutí tohoto pole není možné objednávku kupujícího odeslat. Prodávající/Provozovatel webu​ https://www.hulvattime.com ​eviduje objednávky Kupujících, též eviduje zaškrtnutí uvedeného pole.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.3 Uzavření kupní smlouvy a převzetí zboží kupujícím, je považováno za souhlas s reklamačním řádem a obchodními podmínkami.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.4 Kupující je povinen prokázat nákup zboží v internetovém obchodě https://www.hulvattime.com​ zejména daňovým dokladem vystaveným prodávajícím.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.5 Kupující může pro urychlení reklamačního procesu popsat vady na zboží a zvolit způsob vyřízení reklamace.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.6 Pokud je objednávka rozdělena, popřípadě obsahuje několik částí (nebo zboží), které jsou dodávány v časovém rozestupu, lhůta pro odstoupení od kupní smlouvy začíná běžet od dodání poslední části (zboží). Odstoupení od kupní smlouvy, musí být prodávajícímu odesláno v této lhůtě (nejpozději 14 dní od převzetí poslední části, nebo zboží objednávky).
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.7 Prodávající odpovídá kupujícímu, že věc při převzetí nemá vady. Zejména prodávající odpovídá kupujícímu, že v době, kdy kupující věc převzal,
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            <ul>
                                <li>má věc vlastnosti, které si strany ujednaly, a chybí-li ujednání, takové vlastnosti, které prodávající nebo výrobce popsal nebo které kupující očekával s ohledem na povahu zboží a na základě reklamy jimi prováděné,</li>
                                <li>se věc hodí k účelu, který pro její použití prodávající uvádí nebo ke kterému se věc tohoto druhu obvykle používá,</li>
                                <li>věc odpovídá jakostí nebo provedením smluvenému vzorku nebo předloze, byla-li jakost nebo provedení určeno podle smluveného vzorku nebo předlohy,</li>
                                <li>je věc v odpovídajícím množství, míře nebo hmotnosti,</li>
                                <li>zboží vyhovuje požadavkům právních předpisů.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.8 Projeví-li se vada v průběhu dvanácti měsíců od převzetí, má se za to, že věc byla vadná již při převzetí.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.9 Prodávající je povinen přijmout reklamaci na adrese provozovny nebo v sídle i místě podnikání, pokud to povaha zboží umožňuje. 
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            Adresa pro příjem reklamace zboží je: ​Radlická 48, Praha 5, 15000.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.10 Prodávající písemně vyrozumí kupujícího o převzetí zboží k reklamaci a o výsledku reklamace.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.11 Tento reklamační řád upravuje reklamaci vad, které se vyskytnou u zboží v době dvaceti čtyř (24) měsíců od převzetí.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.12 Tento reklamační řád neupravuje zboží s uvedeným datem spotřeby ve smyslu ust. č. 10.15 těchto podmínek.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.13 Neodstraní-li prodávající vadu včas nebo vadu odmítne odstranit, může kupující požadovat slevu z kupní ceny nebo může od kupní smlouvy odstoupit.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.14 Kupující může uplatnit reklamaci a podle své volby požadovat vyřízení dle bodů v odstavci 5.6 Obchodních podmínek prodávajícího.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.15 Kupující je oprávněn uplatnit právo z vady, která se vyskytne u zboží v době dvaceti čtyř (24) měsíců od převzetí.
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col">
                            9.16 Požádá-li o to kupující, potvrdí mu prodávající v písemné formě, v jakém rozsahu a po jakou dobu trvají jeho povinnosti v případě vadného plnění. Prodávající má povinnosti z vadného plnění nejméně v takovém rozsahu, v jakém trvají povinnosti z vadného plnění výrobce.
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            9.17 Je-li to potřebné, vysvětlí prodávající v potvrzení srozumitelným způsobem obsah, rozsah, podmínky a dobu trvání své odpovědnosti i způsob, jakým lze uplatnit práva z ní plynoucí. V potvrzení prodávající zároveň uvede, že další práva kupujícího, která se ke koupi věci vážou, nejsou dotčena. Nesplnění těchto povinností není na újmu platnosti potvrzení.
                        </div>
                    </div>

                    <div class="row mb-2">
                        <div class="col">
                            <b>Tento reklamační řád nabývá účinnosti ​01.02.2023</b>
                        </div>
                    </div>
                    <div class="row mb-4">
                        <div class="col">
                            <b>Doručovací adresa pro zaslání odstoupení od smlouvy: ​Radlická 48, Praha 5, 15000.</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
export default {
    name: "TaCModal"
}
</script>