<template>
    <section class="order-success">
        <div class="row mb-4 text-center">
            <div class="col">
                <h1>Čau!</h1>
            </div>
        </div>
        <div class="row mb-4 text-center">
            <div class="offset-3 col-6">
                <h3>Zboží po kterým tolik toužíš už pomalu balíme a připravujeme k odeslání za tebou.</h3>
            </div>
        </div>
        <div class="row text-center">
            <div class="offset-3 col-6">
                <h3>Protože nám na každé objednávce záleží, věnujeme jí zvláštní pozornost, ale i tak se jí snažíme odeslat dřív než uplyne 48 hod.</h3>
            </div>
        </div>
    </section>    
</template>

<script>
export default {
    name: "OrderSuccessView"
}
</script>

<style scoped>
    .order-success{
        min-height: 100vh;
    }
</style>