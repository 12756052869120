<template>
    <section>
        <div class="container">
            <div class="row mt-3 text-center">
                <div class="col"><h2>Přidat položku</h2></div>
            </div>

            <div class="row mt-3 text-center">
                <div class="col">
                    <h4>Náhledový obrázek</h4>
                </div>
            </div>

            <div class="row">
                <div class="offset-3 col-6">
                    <div class="mb-3">
                        <input @change="onPreviewImageChange" class="form-control" type="file" id="formFile">
                    </div>
                </div>
            </div>

            <hr>

            <div class="row mt-3 text-center">
                <div class="col">
                    <h4>Informace o produktu</h4>
                </div>
            </div>

            <div class="row text-center">
                <div v-if="previewImageUrl" class="col">
                    <img class="img-fluid" :src="previewImageUrl">
                </div>
                <div class="col">
                    <div class="row text-center">
                        <div class="offset-4 col-5">
                            <select v-model="product.category" class="form-select" aria-label="Default select example">
                                <option :value="null">Kategorie</option>
                                <option value=3>Čepice</option>
                                <option value=1>Mikiny</option>
                                <option value=2>Trička</option>
                                <option value=4>Obrazy</option>
                                <option value=5>Doplňky</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="offset-4 col-5">
                            <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Název položky</label>
                            <input v-model="product.title" type="input" class="form-control" id="exampleFormControlInput1" placeholder="Title#123">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="offset-4 col-5">
                            <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Subtitle položky</label>
                            <input v-model="product.subtitle" type="input" class="form-control" id="exampleFormControlInput1" placeholder="Subtitle#123">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="offset-4 col-5">
                            <div class="mb-3">
                            <label for="exampleFormControlInput1" class="form-label">Cena</label>
                            <input v-model="product.price" type="input" class="form-control" id="exampleFormControlInput1" placeholder="1234,-">
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="offset-3 col-7">
                            <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label"><b>Popis  produktu</b></label>
                            <textarea v-model="product.description" class="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                            </div>
                        </div>
                    </div>
                    
                    <div class="row">
                        <div class="offset-5 col-3">
                            <h6><b>Vlastnosti</b></h6>
                        </div>
                    </div>				
                    
                    <div class="row">
                        <div class="offset-3 col-7">
                            <ul>
                                <li v-for="(property, index) in properties" :key="index">
                                    <input v-model="property.product_property" type="input" class="mb-3 form-control" :placeholder="`Vlastnost ${index + 1}`">
                                </li>
                            </ul>					
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <button @click="addProperty" class="btn btn-outline-warning">Přidat další vlastnost</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <hr>

            <div class="row mt-3 text-center">
                <div class="col">
                    <h4>Velikosti</h4>
                </div>
            </div>

            <div class="row text-center">
                <div class="col-8">
                    <div class="card">
                        <div class="card-body">
                            <div class="row mt-3 text-center">
                                <div class="offset-3 col-6">
                                    <select v-model="sizes.size" class="form-select" aria-label="Default select example">
                                        <option value="null">Velikost</option>
                                        <option value="UNIVERZAL">Univerzal</option>
                                        <option value="XS">XS</option>
                                        <option value="S">S</option>
                                        <option value="M">M</option>
                                        <option value="L">L</option>
                                        <option value="XL">XL</option>
                                        <option value="XXL">XXL</option>
                                    </select>
                                </div> 
                            </div>

                            <div class="row mt-3 text-center">
                                <div class="col-6 offset-3">
                                    <label for="exampleFormControlInput1" class="form-label">Počet ks na skladě</label>
                                    <input v-model="sizes.stock" type="number" class="form-control" id="exampleFormControlInput1" placeholder="Subtitle#123">
                                </div>
                            </div>
                            
                            
                            <button @click="addSize" class="mt-3 btn btn-outline-warning">Přidat velikost</button>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div v-for="(size, index) in allProductSizes" :key="index" class="row">
                        <div class="col">
                            Velikost: {{ size.size }}
                        </div>
                        <div class="col">
                            Na skladě: {{ size.stock }}
                        </div>
                        <div class="col">

                        </div>
                    </div>
                </div>
            </div>

            <hr>
            
            <div class="row mt-3 text-center">
                <div class="col">
                    <h4>Galerie</h4>
                </div>
            </div>

            <div class="row mb-4">
                <div class="offset-3 col-6">
                    <div class="mb-3">
                        <input @change="onGalleryChange" class="form-control" type="file" id="formFileMultiple" multiple>
                    </div>
                </div>
            </div>

            <div v-if="galleryUrls.length" class="row mb-4">
                <div v-for="(galleryUrl, index) in galleryUrls" :key="index" class="col">
                    <img width="250" class="img-fluid" :src="galleryUrl">
                </div>
            </div>
            

            <div class="row mb-5 text-center">
                <div class="col">
                    <button @click="addItem" class="btn btn-outline-success">Přidat produkt</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AddItemView",
    data(){
        return {
            previewImage: null,
            previewImageUrl: null,
            product: {
                category: null,
                price: null,
                title: null,
                subtitle: null,
                description: null
            },
            properties: [
                {
                    "product_property": null,
                },
                {
                    "product_property": null,
                },
                {
                    "product_property": null,
                }
            ],
            sizes: {
                size: null,
                stock: 0
            },
            allProductSizes: [],
            gallery: null,
            galleryUrls: []
        }
    },
    methods: {
        onGalleryChange(e){
            const files = e.target.files;
            this.gallery = files;
            let galleryUrls = [];

            for(let file of files){
                galleryUrls.push(window.URL.createObjectURL(file));
            }

            this.galleryUrls = galleryUrls;
        },
        onPreviewImageChange(e){
            const file = e.target.files[0];
            this.previewImage = file;
            this.previewImageUrl = URL.createObjectURL(file);
        },
        addSize(){
            this.allProductSizes.push(this.sizes);
            this.sizes = {size: null, stock: 0};
        },
        addProperty(){
            this.properties.push({"product_property": null});
        },
        async addItem(){
            let payload = {
                product: this.product,
                sizes: this.allProductSizes,
                properties: this.properties,
                images: [this.gallery, this.previewImage]
            }

            await this.$store.dispatch('addItem', payload);
        }
    }
}
</script>