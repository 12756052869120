<template>
  <!-- Terms & Conditions modal -->
  <TaCModal />

  <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Adresa</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row mb-2 text-muted">
          <div class="col">
            Radlická 48
          </div>
        </div>
        <div class="row mb-2">
          <div class="col text-muted">
            150 00, Praha
          </div>
        </div>
        <div class="row mb-2">
          <div style="font-size: 0.5rem;" class="col text-muted">
            Adresa slouží pouze pro přijímání pošty.
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>

  <header class="landing-page">
    <img class="img-fluid" width="500" src="/static/logo.png">
    <nav :class="{'fixed-top': isSticky, 'mt-3': !isSticky}" class="mb-5 navbar-main navbar navbar-expand-lg">
      <div class="row">
        <div class="col">
          <div class="menu-item-container">
          <img class="stroke-effect" width="100" src="/static/stroke_pink.png">
            <div class="menu-item">
              <router-link :to="{ name: 'home', hash: '#items'}">SHOP</router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="menu-item-container">
            <img class="stroke-effect" width="100" src="/static/stroke_yellow.png">
              <div class="menu-item">
                <router-link :to="{ name: 'colors', hash: '#s'}">COLORS</router-link>
              </div>
            </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="menu-item-container">
            <img class="stroke-effect" width="100" src="/static/stroke_red.png">
              <div class="menu-item">
                <router-link :to="{ name: 'cart', hash: '#s'}"><img width="20" class="mb-1 shopping-bag" src="/static/shopping_bag.png">
                  <span class="position-absolute top-0 translate-small badge rounded-pill bg-danger">
                    {{ cartLength }}
                  </span>
                </router-link>
              </div>
            </div>
        </div>
      </div>

      <div v-if="isAuthenticated" class="row">
        <div class="col">
          <div class="menu-item-container">
              <img class="stroke-effect" width="100" src="/static/stroke_red.png">
                <div class="menu-item">
                  <div class="dropdown">
                    <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img width="20" class="shopping-bag" src="/static/settings.png">
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <router-link class="dropdown-item" to="/admin/add-item">Přidat produkt</router-link>
                      </li>
                      <li>
                        <router-link class="dropdown-item" to="/admin/orders">Správa objednávek</router-link>
                      </li>
                      <li class="mr-5">
                        <router-link class="dropdown-item" to="/admin/contacts">Kontakty</router-link>
                      </li>
                      <hr>
                      <li>
                        <div @click="logout" class="dropdown-item">
                          Odhlásit se
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </nav>
    <!-- <nav class="mt-3 mb-5 navbar-main navbar navbar-expand-lg">
          <ul :class="{'fixed-top': isSticky}" class="navbar-nav">
            <div class="row">
              <div class="col-sm-3">
                  <div class="menu-item-container">
                  <img class="stroke-effect" width="100" src="/static/stroke_pink.png">
                    <div class="menu-item">
                      <router-link :to="{ name: 'home', hash: '#items'}">SHOP</router-link>
                    </div>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                  <div class="menu-item-container">
                  <img class="stroke-effect" width="100" src="/static/stroke_yellow.png">
                    <div class="menu-item">
                      <router-link :to="{ name: 'colors', hash: '#s'}">COLORS</router-link>
                    </div>
                  </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                  <div class="menu-item-container">
                  <img class="stroke-effect" width="100" src="/static/stroke_red.png">
                    <div class="menu-item">
                      <router-link :to="{ name: 'cart', hash: '#s'}"><img width="20" class="mb-1 shopping-bag" src="/static/shopping_bag.png">
                        <span class="position-absolute top-0 translate-small badge rounded-pill bg-danger">
                          {{ cartLength }}
                        </span>
                      </router-link>
                    </div>
                  </div>
              </div>
            </div>
            <li v-if="isAuthenticated" class="nav-item">
              <div class="menu-item-container">
              <img class="stroke-effect" width="100" src="/static/stroke_red.png">
                <div class="menu-item">
                  <div class="dropdown">
                    <button class="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <img width="20" class="shopping-bag" src="/static/settings.png">
                    </button>
                    <ul class="dropdown-menu">
                      <li>
                        <router-link class="dropdown-item" to="/admin/add-item">Přidat produkt</router-link>
                      </li>
                      <li>
                        <router-link class="dropdown-item" to="/admin/orders">Správa objednávek</router-link>
                      </li>
                      <li class="mr-5">
                        <router-link class="dropdown-item" to="/admin/contacts">Kontakty</router-link>
                      </li>
                      <hr>
                      <li>
                        <div @click="logout" class="dropdown-item">
                          Odhlásit se
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
    </nav> -->
  </header>

  <div class="container mt-5">
    <router-view/>
  </div>

    <!-- <footer :style="{backgroundImage: `url(${require('@/../public/static/footer_background.png')})`}" class="mt-5 row text-center"> -->
    <footer style="background-image: url('/static/footer_background.png')" class="mt-5 row text-center">

      <div class="col-sm-12 col-md-4 mb-3">
        <h5 class="footer-title">Užitečné odkazy</h5>
        <ul class="nav mt-3 footer-links flex-column">
          <li class="nav-item mb-2">
            <router-link :to="{ name: 'complaint', hash: '#s'}">
              Reklamace a vrácení
            </router-link>
          </li>
          <li class="nav-item mb-2 text-muted" data-bs-toggle="modal" data-bs-target="#tac">Obchodní podmínky</li>
        </ul>
      </div>

      <div class="col-sm-12 col-md-4 mb-3">
        <h5 class="footer-title">Kontakt</h5>
        <ul class="nav mt-3 footer-links flex-column">
          <li class="nav-item mb-2">
            <router-link :to="{ name: 'colors', hash: '#contact'}">
              Kontaktní formulář
            </router-link>
          </li>
          <li class="nav-item mb-2 text-muted" data-bs-toggle="modal" data-bs-target="#exampleModal">Adresa</li>
        </ul>
      </div>

              <!-- <div class="row mb-2 text-muted">
          <div class="col">
            Radlická 48
          </div>
        </div>
        <div class="row mb-2">
          <div class="col text-muted">
            150 00, Praha
          </div>
        </div>
        <div class="row mb-2">
          <div style="font-size: 0.5rem;" class="col text-muted">
            Adresa slouží pouze pro přijímání pošty.
          </div>
        </div> -->

      <div class="col-sm-12 col-md-4 mb-3">
        <h5 class="footer-title">Sociální sítě</h5>
        <ul class="nav mt-3 flex-column">
          <li class="nav-item mb-2">
            <div class="row footer-links">
              <div class="col nav-item text-muted">
                <router-link :to="{ name: 'history', hash: '#s'}">Historie</router-link>
              </div>
            </div>
            <div class="row mt-2">
              <div class="offset-md-4 col-sm-12 col-md-2 mb-3">
                <a href="https://www.instagram.com/hulvattime/" target="_blank">
                  <img class="footer-icon" width="25" src="/static/instagram_icon.png">
                </a>
              </div>
              <div class="col-sm-12 col-md-2">
                <a href="https://www.youtube.com/@yankees_19xx" target="_blank">
                  <img class="footer-icon" width="25" src="/static/youtube_icon.png">
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </footer>
</template>

<script>
import TaCModal from "./components/TaCModal.vue";

export default {
  name: "App",
  components: {
    TaCModal
  },
  data(){
    return {
      isSticky: false
    }
  },
  computed: {
    isAuthenticated(){
      return this.$store.getters.isAuthenticated;
    },
    cartLength(){
      return this.$store.getters.getCart.length;
    }
  },
  async created(){
    window.addEventListener('scroll', this.handleScroll);

    this.$store.dispatch('setLoading', true);

    await this.$store.dispatch('getProducts');

    this.$store.dispatch('setLoading', false);
  },
  methods: {
    handleScroll(){
      if (window.pageYOffset >= 555) this.isSticky = true;
      else this.isSticky = false;
    },
    logout(){
      this.$store.dispatch('logout');

      this.$router.push("/");
    }
  }
}
</script>

<style>
  footer{
    /* background-image: url("/static/footer_background.png"); */
    border-top: 2px solid black;
    padding-top: 5%;
    background-size:     cover;
    background-repeat:   no-repeat;
    background-position: center;
  }
  body, html{
    font-family: 'Roboto', sans-serif !important;
    overflow-x: hidden;
  }
  .dropdown-item{
    color: black !important;
  }
  .landing-page{
    background-color: black;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /* NAVBAR */
  nav{
    font-size: 1.1rem;
  }
  nav .navbar-main{
    background-color: black;
  }
  .navbar{
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    justify-content: center !important;
  }
  .nav-item a{
    color: #59656e;
    text-decoration: none;
  }
  .navbar a, .navbar a:hover, .navbar a:focus, .navbar{
    color: white;
    text-decoration: none;
  }
  .navbar .nav-link .active{
    color: white;
  }
  .navbar li{
    padding-left: 10px;
    padding-right: 10px;
  }
  .menu-item-container{
    position: relative;
  }
  .menu-item{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .menu-item-container img{
    transform: rotate(10deg);
  }
  .shopping-bag{
    margin-top: 3px;
    transform: rotate(0deg) !important;
  }
  .stroke-effect{
    opacity: 0; 
  }
  .menu-item-container:hover .stroke-effect{
    opacity: 1;
    transition: .4s ease-in;
  }
  .fixed-top{
    background-color: black !important;
    border-bottom: 1px solid white;
    justify-content: center;
  }
  /* FOOTER */
  .footer-links{
    list-style: none;
  }
  .footer-links{
    text-decoration: none;
    color: black;
  }
  .footer-links .nav-item:hover{
    cursor: pointer;
    font-weight: bold;
  }
  .footer-icon:hover{
    cursor: pointer;
  }
  
  @media (min-width: 500px) {
    nav{
      min-width: 450px;
    }
  }

  @media (max-width: 767px) {
    .footer-title {
      margin-top: 10%;
    }
    .product-container{
      margin-top: 35px !important;
    }

    @media (max-width: 375px) {
        footer{
          /* background-image: url("/static/footer_background.png"); */
          border-top: 2px solid black;
          background-size:     850px 600px;
          background-repeat:   no-repeat;
          /* background-position: center; */
        }
    }

    /* nav .show{
      padding-bottom: 100px;
    }
    .menu-item-container img{
      transform: rotate(10deg);
      top: 30%;
      left: 65%;
      transform: translate(65%, 30%);
    } */
  }

  /* @media (max-width: 991px) {
    nav{
      min-width: 550px;
      z-index: 999 !important;
    }
    .navbar-collapse{
      height: 100%;
    }

    nav .show{
      padding-bottom: 100px;
    }
    .menu-item-container img{
      transform: rotate(10deg);
      top: 30%;
      left: 65%;
      transform: translate(65%, 30%);
    }
  } */

  /* @media (max-width: 608px) {
    nav{
      min-width: 350px;
      z-index: 999 !important;
      font-size: 1rem;
    }

    .navbar li{
      padding: 0;
    }

    nav .show{
      padding-bottom: 100px;
    }
    .menu-item-container img{
      transform: rotate(0deg);
      justify-content: center;
      left: 110%;
      transform: translateX(110%)
    }
  } */
</style>
