<template>
  <div class="container">
    <div id="items" class="row">
      <div v-for="product in products" :key="product.id" class="col-md-4">
        <Product :id="product.id" />
      </div>
    </div>

  </div>
</template>

<script>
import Product from "../components/Product.vue";

export default {
  name: 'HomeView',
  components: {
    Product
  },
  computed: {
    products(){
      return this.$store.getters.getProducts;
    }
  }
}
</script>
