<template>
    <section>
        <div id="s" class="row mt-5 text-center">
            <div class="col">
                <img class="img-fluid" src="/static/history.jpeg">
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-8 offset-2">
                20:15 je rok, kdy HULVATTIME vznikl. Byl to mezi lidmi tak obrovský boom, že si tetovali naše logo na svou kůži. První prodeje tohoto módního fenoménu probíhaly z kufru auta! Značka se stala symbolem nejen kvalitního a stylové oblečení, ale také svobody a nezávislosti. 

                Hulvattime byl úspěšně prodáván i na Floridě, a to díky příteli, který tam má své kontakty. Během krátkého období se podařilo prodat více než 300 ks oblečení. 

                Jedna ze spoluprací, kterou jsme měli, byla s klukama z UKAŽ KÁRU. Děkuji klukům, že nám dali důvěru a mohli jsme pro ně vytvářet vůbec první design na jejich první merch. Měli jsme tu čest zajistit výrobu pro několik muzikantů a známých osobností. Vznikla z toho silná spolupráce s Kubou (Dyecomb.sw), která funguje dodnes. 

                Stojí za zmínku také to, že jsme během pandemie vytvořili umělecké roušky. Rozhodli jsme se využít zisk z prodeje a věnovat ho na podporu dvou útulků pro kočky. Bylo nám ctí pomoci a podpořit tak útulky, které se starají o zvířata, která potřebují naši pomoc. 

                Nevytváříme jenom komerční oblečení, ale i originální, kdy každý kus je originál, a tak se nemůže stát, že by jsi měl na sobě něco, co už někdo jiný má. Říkáme tomu COLORS, aneb každý kus je originál!

                Není to jen oblečení, ale spíše způsob života. Řídíme se vlastními pravidly a hodnotami, které odrážejí individualitu a svobodu. Hulvattime není určen pro všechny. Nejde jen o módu, ale také o způsob myšlení a přístup k životu. Lidé, kteří se řídí tímto životním stylem, se nebojí vybočovat z davu a díky tomu mohou vytvářet svou vlastní cestu ke splnění svých cílů.
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "HistoryView"
}
</script>

<style scoped>
    section{
        min-height: 100vh;
    }
</style>