<template>
    <section>
        <div id="s" class="row">
            <div class="mt-2 offset-1 col-10">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title text-center">Potřebuješ vyměnit, vrátit nebo reklamovat zboží?</h5>
                        <div class="row mt-5">
                            <div class="col">
                                1. Stáhni si a vyplň <a href="/static/reklamacni_formular.jpg" download>tento formulář</a>.
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                2. Potřebujeme taky tvoje jméno, adresu a telefon. Pokud chceš vrátit peníze, nezapomeň číslo účtu.
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col">
                                3. Zabalené zboží zašli na adresu:
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="offset-1 col">
                                Radlická 48
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="offset-1 col">
                                Praha 5, 15000
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "ComplaintView"
}
</script>

<style scoped>
    section{
        min-height: 100vh;
    }
</style>