<template>
    <section>
        <div class="container">
            <div class="row start">
                <div class="col">
                    <router-link to="/admin/orders">
                        <button>Správa objednávek</button>
                    </router-link>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <router-link to="/admin/items-management">
                        <button>Správa produktů</button>
                    </router-link>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <router-link to="/admin/contacts">
                        <button>Nové kontakty</button>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AdminPanelView.vue"
}
</script>

<style scoped>
    section{
        min-height: 100vh;
        text-align: center;
    }
    .start{
        margin-top: 5%;
    }
    button{
        margin-top: 2%;
    }
</style>