<template>
    <div class="product-container">
        <router-link :to="{name: 'product', hash: '#s', params: {id: id}}">
            <img class="img-fluid product-image" :src="getIndexImage()">
            <!-- <img class="img-fluid product-image" :src="`http://127.0.0.1:8000${getIndexImage()}`"> -->
            <div class="product-title-container">
                <div class="">
                    <div class="row product-title">
                        <div class="col">
                            <b>{{ product.title }}</b>
                        </div>
                    </div>
                    <div class="row product-subtitle">
                        <div class="col">
                            <b>{{ product.subtitle }}</b>
                        </div>
                    </div>
                    <div class="row mt-3 price">
                        <div class="col">
                            <b>{{ product.price }},-</b>
                        </div>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    props: ["id"],
    name: "Product",
    computed: {
        product(){
            return this.$store.getters.getProductById(this.id);
        }
    },
    methods: {
        getIndexImage(){
            return this.product.images.find(image => image.index === true).image;
        }
    }
}
</script>

<style scoped>
    .product-container{
        position: relative;
        margin: 10px;
    }
    .product-container a{
        color: black;
    }
    .product-image{
        opacity: 1;
        display: block;
        /* width: 100%; */
        /* height: auto; */
        transition: .5s ease;
        backface-visibility: hidden;
    }
    .product-container:hover .product-image{
        opacity: .4;
        cursor: pointer;
    }
    .product-container:hover .product-title-container{
        opacity: 1;
    }
    .product-title-container{
        width: 100%;
        transition: .5s ease;
        opacity: 0;
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -45%);
        -ms-transform: translate(-50%, -45%);
        text-align: center;
    }
    .product-title{
        font-size: 26px;
        text-transform: uppercase;
        /* font-weight: bold;  */
    }
    .product-title a{
        color: black;
        text-decoration: none;
    }
    .product-title:hover{
        cursor: pointer;
    }
    .product-subtitle{
        font-size: 18px;
    }
    .price{
        font-size: 26px;
    }
</style>