<template>
    <section>
        <div class="container">
            <!-- {{ order }} -->
            
            <div class="row">
                <div class="col">
                    <h4>Objednávka číslo <b>#{{ order.id }}</b></h4>
                </div>
            </div>
            
            <hr>
            
            <div class="row mt-5">
                <div class="col">
                    <b>Informace o objednávce</b>
                </div>
            </div>
            
            <div class="row">
                <div class="col">
                    Jméno: {{ order.name }} Příjmení: {{ order.surname }}
                </div>
                <div class="col">
                    Telefon: {{ order.phone }}
                </div>
            </div>
            
            <div class="row mt-5">
                <div class="offset-2 col-3 mt-2">
                <select class="form-select" aria-label="Default select example">
                <option selected>Stav objednávky</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
                </select>

                </div>
                <div class="offset-1 col mt-2">
                    Email: {{ order.email }}
                </div>
            </div>
            
            <div class="row mt-5">
                <div class="col">
                    <b>Obsah objednávky</b>
                </div>
            </div>
        
            <hr>
            
            <div v-for="item in order.items" :key="item.id" class="row">
                <div class="col">
                    <img width="200" class="img-fluid" src="http://menny.cz/10460-large_default/panska-bunda-calvin-klein-2668.webp?format=webp">
                </div>
                <div class="col">
                    <div class="row">
                        <div class="col">
                            {{ item.title }} {{ item.subtitle }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            {{ order.sizes[0].size }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            {{ item.price }},-
                        </div>
                    </div>
                </div>
            </div>
            
            <hr>
            
            <div class="row">
                <div class="col">
                    Hodnota objednávky: xxx ,-
                </div>
            </div>
            
            <div class="row mb-5">
                <div class="col">
                    <button type="button" class="btn btn-success mt-5">Uložit objednávku</button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "OrderDetailView",
    computed: {
        order(){
            return this.$store.getters.getOrderById(parseInt(this.$route.params.id));
        }
    }
}
</script>