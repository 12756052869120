<template>
    <section>
        <div class="container">
            <div v-if="errors" class="row start">
                <div class="col">
                    {{ errors }}
                </div>
            </div>
            <div class="row mt-5 text-center">
                <div class="offset-5 col">
                    <div class="row">
                        <div class="col-4">
                            Username: <input v-model="user.username" type="text">
                        </div>
                    </div>
                    <div class="row mt-3"> 
                        <div class="col-4">
                            Heslo: <input v-model="user.password" type="password">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-4">
                            <button class="btn btn-warning" @click="login">Přihlásit se</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AdminLoginView.vue",
    data(){
        return {
            loading: false,
            errors: null,
            user: {
                username: "",
                password: ""
            }
        }
    },
    mounted(){
        if(this.$store.getters.isAuthenticated) this.$router.push("/");
    },
    methods: {
        async login(){
            this.loading = true;
            let response = await this.$store.dispatch('login', this.user);
            this.loading = false;

            if(response.status === 200){
                this.$router.push("/");
            }
            else{
                this.errors = response.data.detail;
                this.user = {};
            }
        }
    }
}
</script>

<style scoped>
    section{
        min-height: 100vh;
        text-align: center;
    }
    .start{
        margin-top: 5%;
    }
    button{
        margin-top: 2%;
    }
</style>