import axios from 'axios';

class TransportService{
    async fetchTransportOptions(){
        let response = await axios.get("/api/v1/transport/options/");

        return response;
    }
}

export default new TransportService();